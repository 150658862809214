import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import ProductFeatures from "./ProductFeatures";
import ProductGrid from "./ProductGrid";

const Listing = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Ecommerce" title="Products" mainTitle="Products" />
      <Container fluid={true} className="product-wrapper" id="product-wrapper">
        <div className="product-grid">
          <ProductFeatures />
          <ProductGrid />
        </div>
      </Container>
    </Fragment>
  );
};
export default Listing;
