export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard`,
      },
      {
        title: "listing",
        icon: "builders",
        type: "link",
        path: `${process.env.PUBLIC_URL}/listing`,
      },
      {
        title: "Developer's Projects",
        icon: "bonus-kit",
        type: "link",
        path: `${process.env.PUBLIC_URL}/developer-projects`,
      },
      {
        title: "CRM",
        icon: "user",
        type: "link",
        path: `${process.env.PUBLIC_URL}/crm`,
      },
      {
        title: "Home Loan Calculator",
        icon: "table",
        type: "link",
        path: `${process.env.PUBLIC_URL}/calculator`,
      },
      {
        title: "Event Calendar",
        icon: "calendar",
        type: "link",
        path: `${process.env.PUBLIC_URL}/calendar`,
      },
    ],
  },
];
