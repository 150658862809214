import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Callback from "../Auth/Callback";
import Signin from "../Auth/Signin";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import {
  authHeader,
  configureFakeBackend,
  handleResponse,
} from "../Services/fack.backend";
import { authRoutes } from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";

// setup fake backend
configureFakeBackend();
const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");
  useEffect(() => {
    let abortController = new AbortController();
    const requestOptions = { method: "GET", headers: authHeader() };
    fetch("/users", requestOptions).then(handleResponse);

    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={"/"}>
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={"/"} element={<PrivateRoute />}>
              {login || authenticated || jwt_token ? (
                <>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}`}
                    element={
                      <Navigate to={`${process.env.PUBLIC_URL}/calculator/`} />
                    }
                  />
                  <Route
                    exact
                    path={`/`}
                    element={
                      <Navigate to={`${process.env.PUBLIC_URL}/calculator/`} />
                    }
                  />
                </>
              ) : (
                ""
              )}
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>
            <Route
              path={`${process.env.PUBLIC_URL}/callback`}
              render={() => <Callback />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              element={<Signin />}
            />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
