import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const CheckoutTableData = ({ data, setData, info, setInfo }) => {
  const {
    register,
    formState: { errors },
  } = useForm();

  const updateProjectName = (event) => {
    setInfo({ ...info, project: event.target.value });
  };
  const updateDetails = (event) => {
    setInfo({ ...info, description: event.target.value });
  };

  const reCalculate = (e) => {
    setData((prev) => {
      let helper = { ...prev };
      helper[e.target.name] = parseFloat(e.target.value);
      return helper;
    });
  };

  const showHidePagibig = (e) => {
    setData({ ...data, showPagibig: e.target.checked });
  };

  const showHideBank = (e) => {
    setData({ ...data, showBank: e.target.checked });
  };

  return (
    <Fragment>
      <Col xl="6" sm="12">
        <Form className="needs-validation">
          <FormGroup className="mb-3">
            <Label>Project / Model Unit</Label>
            <input
              className="form-control"
              type="text"
              name="project"
              placeholder={info.project}
              onChangeCapture={updateProjectName}
            />
            <span style={{ color: "#ff5370" }}>
              {errors.loanAmount && "First name is required"}
            </span>
          </FormGroup>
          <FormGroup className="mb-3">
            <Label>Home Details</Label>
            <input
              className="form-control"
              type="text"
              name="details"
              placeholder={info.description}
              onChangeCapture={updateDetails}
            />
            <span style={{ color: "#ff5370" }}>
              {errors.loanAmount && "First name is required"}
            </span>
          </FormGroup>
          <FormGroup className="mb-3">
            <Label>Total Contract Price</Label>
            <input
              className="form-control"
              type="text"
              name="totalContractPrice"
              placeholder={data.totalContractPrice}
              onChangeCapture={reCalculate}
              {...register("totalContractPrice", { required: true })}
            />
            <span style={{ color: "#ff5370" }}>
              {errors.loanAmount && "First name is required"}
            </span>
          </FormGroup>
          <Row>
            <FormGroup className="mb-3 col-sm-4">
              <Label>Reservation Fee</Label>
              <input
                className="form-control"
                type="text"
                name="reservationFee"
                placeholder={data.reservationFee}
                {...register("reservationFee", { required: true })}
                onChangeCapture={reCalculate}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.lastName && "Last name is required"}
              </span>
            </FormGroup>
            <FormGroup className="mb-3 col-sm-4">
              <Label>Equity</Label>
              <input
                className="form-control"
                type="text"
                name="equity"
                placeholder={data.equity}
                onChangeCapture={reCalculate}
                {...register("equity", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.equity && "Please enter number for phone."}
              </span>
            </FormGroup>
            <FormGroup className="mb-3 col-sm-4">
              <Label>Equity Terms: (Months)</Label>
              <input
                className="form-control"
                type="text"
                name="equityTerms"
                placeholder={data.equityTerms}
                onChangeCapture={reCalculate}
                {...register("equityTerms", { required: true })}
              />
            </FormGroup>
          </Row>

          <FormGroup className="mb-3">
            <Label className="d-block" htmlFor="chk-ani1">
              <Input
                className="checkbox_animated"
                id="opt-bank"
                type="checkbox"
                onClick={showHideBank}
                defaultChecked={true}
              />
              Bank Financing
            </Label>
          </FormGroup>

          <FormGroup className={data.showBank ? "mb-3" : "hidden mb-3"}>
            <Label>Interest Rate (%):</Label>
            <input
              className="form-control"
              type="text"
              name="bankInterestRate"
              placeholder={data.bankInterestRate}
              onChangeCapture={reCalculate}
              {...register("bankInterestRate", { required: true })}
            />
            <span style={{ color: "#ff5370" }}>
              {errors.bankInterestRate && "First name is required"}
            </span>
          </FormGroup>

          <FormGroup className="mb-3">
            <Label className="d-block" htmlFor="chk-ani1">
              <Input
                className="checkbox_animated"
                id="opt-pagibig"
                type="checkbox"
                onClick={showHidePagibig}
              />
              Pag-Ibig Financing
            </Label>
          </FormGroup>

          <FormGroup className={data.showPagibig ? "mb-3" : "hidden mb-3"}>
            <Label>Preferred Fixed Pricing Period:</Label>
            <Input
              className="form-control"
              type="select"
              name="pagibigInterestRate"
              onChange={reCalculate}
            >
              <option value={6.25}>3 Years; 6.25%</option>
              <option value={6.5}>5 Years; 6.50%</option>
              <option value={7.12}>10 Years; 7.125%</option>
              <option value={7.75}>15 Years; 7.75%</option>
              <option value={8.5}>20 Years; 8.50%</option>
              <option value={9.125}>25 Years; 9.125%</option>
              <option value={9.75}>30 Years; 9.75%</option>
            </Input>
          </FormGroup>
        </Form>
      </Col>
    </Fragment>
  );
};
export default CheckoutTableData;
