import React, { Fragment } from "react";
import { Card, Col, Media } from "reactstrap";
import timeline4 from "../../../assets/images/social-app/timeline-4.png";
import Followers from "../../Bonus-Ui/Tour/Leftbar/Followers";
import Followings from "../../Bonus-Ui/Tour/Leftbar/Followings";
import Friendss from "../../Bonus-Ui/Tour/Leftbar/Friends";
import LatestPhotoss from "../../Bonus-Ui/Tour/Leftbar/LatestPhoto";

const RightBar = () => {
  return (
    <Fragment>
      <Followers colClass={"col-xl-12 col-sm-6 xl-50 box-col-6"} />
      <Followings colClass={"col-xl-12 col-sm-6 xl-50 box-col-6"} />
      <LatestPhotoss colClass={"col-xl-12 col-sm-6 xl-50 box-col-6"} />
      <Friendss colClass={"col-xl-12 col-sm-6 xl-50 box-col-6"} />
      <Col xl="12 xl-50 box-col-6">
        <Card className="image-sec">
          <Media className="img-fluid" src={timeline4} alt="" />
        </Card>
      </Col>
    </Fragment>
  );
};

export default RightBar;
