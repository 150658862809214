import React, { Fragment, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import ConfigDB from "../Config/ThemeConfig";
import AnimationThemeContext from "../_helper/AnimationTheme";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Taptop from "./TapTop";

const AppLayout = ({ children, classNames, ...rest }) => {
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;

  return (
    <Fragment>
      {/* <Loader /> */}
      <Taptop />
      <div
        className={`page-wrapper compact-wrapper`}
        sidebar-layout="stroke-svg"
        id="pageWrapper"
      >
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <TransitionGroup {...rest}>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animationTheme}
                unmountOnExit
              >
                <div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
