import React, { Fragment, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import HeaderCard from "../../Common/HeaderCard";
import CheckoutTableData from "../PageLayout/CheckoutTableData";
import ProductPlaceOrder from "../PageLayout/ProductPlaceOrder";
const SamplePage = () => {
  const [data, setData] = useState({
    totalContractPrice: 1000000,
    reservationFee: 20000,
    equity: 250000,
    equityTerms: 12,
    bankInterestRate: 8,
    pagibigInterestRate: 6.25,
    pagibigEquity: 0,
    showPagibig: false,
    showBank: true,
    bankFinancing: {
      years30: 0,
      years25: 0,
      years20: 0,
      years15: 0,
      years10: 0,
      years5: 0,
    },
    pageIbigFinancing: {
      years30: 0,
      years25: 0,
      years15: 0,
      years10: 0,
      years5: 0,
    },
  });

  const [info, setInfo] = useState({
    project: "Sample Computation",
    description: "2 Bedrooms, Lot Area SQM, Townhouse",
  });

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="checkout m-t-20">
              <HeaderCard title="Home Loan Calculator" />
              <CardBody>
                <Row>
                  <CheckoutTableData
                    data={data}
                    setData={setData}
                    info={info}
                    setInfo={setInfo}
                  />
                  <ProductPlaceOrder
                    data={data}
                    setData={setData}
                    info={info}
                    setInfo={setInfo}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SamplePage;
