import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import TimelineTab from "./TimelineTab";

const ForPosting = () => {
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Team Sharing"
        parent="Apps"
        title="Team Sharing"
      />
      <Container fluid={true}>
        <div className="user-profile social-app-profile">
          <TimelineTab />
        </div>
      </Container>
    </Fragment>
  );
};

export default ForPosting;
