import React, { Fragment, useRef } from "react";
import { Button, Col, Table } from "reactstrap";
import { H4, LI, UL } from "../../../AbstractElements";

import html2canvas from "html2canvas";

const ProductPlaceOrder = ({ data, setData, info, setInfo }) => {
  const ref = useRef(null);

  const downloadQuotation = async () => {
    /*const dataUrl = await toPng(ref.current);

    // download image
    const link = document.createElement("a");
    link.download = "sample-quotation.png";
    link.href = dataUrl;
    link.click();*/

    html2canvas(document.querySelector(".file-content"), {
      backgroundColor: "#f6f8fa",
      useCORS: true,
      removeContainer: true,
      scale: 2,
      ignoreElements: function (element) {
        if ("download" === element.id) {
          return true;
        }
        return false;
      },
    }).then((canvas) => {
      const a = document.createElement("a");
      a.href = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = `sample-quote.jpg`;
      a.click();
    });
  };

  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let { bfYears30, bfYears25, bfYears20, bfYears15, bfYears10, bfYears5 } = 0;

  let {
    pagibigYears30,
    pagibigYears25,
    pagibigYears20,
    pagibigYears15,
    pagibigYears10,
    pagibigYears5,
  } = 0;

  const {
    totalContractPrice,
    reservationFee,
    equity,
    bankInterestRate,
    pagibigInterestRate,
  } = data;

  const interestPerMonth = bankInterestRate / 100 / 12;
  const loanAmount = totalContractPrice - (reservationFee + equity);

  const pagibigInterestPerMonth = pagibigInterestRate / 100 / 12;

  if (totalContractPrice > reservationFee) {
    bfYears30 =
      (loanAmount * interestPerMonth * (1 + interestPerMonth) ** (30 * 12)) /
      ((1 + interestPerMonth) ** (30 * 12) - 1);
    bfYears25 =
      (loanAmount * interestPerMonth * (1 + interestPerMonth) ** (25 * 12)) /
      ((1 + interestPerMonth) ** (25 * 12) - 1);
    bfYears20 =
      (loanAmount * interestPerMonth * (1 + interestPerMonth) ** (20 * 12)) /
      ((1 + interestPerMonth) ** (20 * 12) - 1);
    bfYears15 =
      (loanAmount * interestPerMonth * (1 + interestPerMonth) ** (15 * 12)) /
      ((1 + interestPerMonth) ** (15 * 12) - 1);
    bfYears10 =
      (loanAmount * interestPerMonth * (1 + interestPerMonth) ** (10 * 12)) /
      ((1 + interestPerMonth) ** (10 * 12) - 1);
    bfYears5 =
      (loanAmount * interestPerMonth * (1 + interestPerMonth) ** (5 * 12)) /
      ((1 + interestPerMonth) ** (5 * 12) - 1);

    pagibigYears30 =
      (loanAmount *
        pagibigInterestPerMonth *
        (1 + pagibigInterestPerMonth) ** (30 * 12)) /
      ((1 + pagibigInterestPerMonth) ** (30 * 12) - 1);
    pagibigYears25 =
      (loanAmount *
        pagibigInterestPerMonth *
        (1 + pagibigInterestPerMonth) ** (25 * 12)) /
      ((1 + pagibigInterestPerMonth) ** (25 * 12) - 1);
    pagibigYears20 =
      (loanAmount *
        pagibigInterestPerMonth *
        (1 + pagibigInterestPerMonth) ** (20 * 12)) /
      ((1 + pagibigInterestPerMonth) ** (20 * 12) - 1);
    pagibigYears15 =
      (loanAmount *
        pagibigInterestPerMonth *
        (1 + pagibigInterestPerMonth) ** (15 * 12)) /
      ((1 + pagibigInterestPerMonth) ** (15 * 12) - 1);
    pagibigYears10 =
      (loanAmount *
        pagibigInterestPerMonth *
        (1 + pagibigInterestPerMonth) ** (10 * 12)) /
      ((1 + pagibigInterestPerMonth) ** (10 * 12) - 1);
    pagibigYears5 =
      (loanAmount *
        pagibigInterestPerMonth *
        (1 + pagibigInterestPerMonth) ** (5 * 12)) /
      ((1 + pagibigInterestPerMonth) ** (5 * 12) - 1);
  }

  return (
    <Fragment>
      <Col xl="6" sm="12">
        <div className="checkout-details file-content" ref={ref}>
          <div id="quotation">
            <div className="order-box">
              <div className="title-box">
                <div className="project-name">
                  <H4>{info.project} </H4>
                  <p>{info.description}</p>
                </div>
              </div>
              <UL attrUL={{ className: "simple-list border-0  sub-total" }}>
                <LI attrLI={{ className: "border-0 bg-transparent" }}>
                  Total Contract Price{" "}
                  <span className="count">
                    ₱{numberFormatter.format(data.totalContractPrice)}
                  </span>
                </LI>
                <LI attrLI={{ className: "border-0 bg-transparent" }}>
                  Reservation Fee{" "}
                  <span className="count">
                    ₱{numberFormatter.format(data.reservationFee)}
                  </span>
                </LI>
                <LI attrLI={{ className: "border-0 bg-transparent" }}>
                  Equity (
                  {numberFormatter.format(
                    data.equity / data.totalContractPrice
                  ) * 100}
                  %)
                  <span className="count">
                    ₱{numberFormatter.format(data.equity)}{" "}
                  </span>
                </LI>

                <LI attrLI={{ className: "border-0 bg-transparent" }}>
                  Monthly Equity ({data.equityTerms} Months)
                  <span className="count">
                    ₱{numberFormatter.format(data.equity / data.equityTerms)}
                  </span>
                </LI>
                <LI attrLI={{ className: "border-1 bg-transparent" }}>
                  Net Loanable Amount
                  <span className="count">
                    ₱{numberFormatter.format(loanAmount)}
                  </span>
                </LI>
                <LI
                  attrLI={{
                    className: !data.showBank
                      ? "hidden"
                      : "border-0 bg-transparent",
                  }}
                >
                  Bank Financing
                  <span className="count">({data.bankInterestRate}% IR)</span>
                  <div className="table-responsive m-t-10">
                    <Table>
                      <thead>
                        <tr className="border-bottom-primary">
                          <th scope="col">Term</th>
                          <th scope="col">Monthly Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={`border-bottom-green`}>
                          <td>30 Years</td>
                          <td>₱ {numberFormatter.format(bfYears30)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>25 Years</td>
                          <td>₱ {numberFormatter.format(bfYears25)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>20 Years</td>
                          <td>₱ {numberFormatter.format(bfYears20)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>15 Years</td>
                          <td>₱ {numberFormatter.format(bfYears15)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>10 Years</td>
                          <td>₱ {numberFormatter.format(bfYears10)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>5 Years</td>
                          <td>₱ {numberFormatter.format(bfYears5)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </LI>
                <LI
                  attrLI={{
                    className: !data.showPagibig
                      ? "hidden"
                      : "border-0 bg-transparent",
                  }}
                >
                  PAGIBIG Financing{" "}
                  <span className="count">
                    ({data.pagibigInterestRate}% IR){" "}
                  </span>
                  <div className="table-responsive  m-t-10">
                    <Table>
                      <thead>
                        <tr className="border-bottom-primary">
                          <th scope="col">Term</th>
                          <th scope="col">Monthly Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={`border-bottom-green`}>
                          <td>30 Years</td>
                          <td>₱ {numberFormatter.format(pagibigYears30)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>25 Years</td>
                          <td>₱ {numberFormatter.format(pagibigYears25)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>20 Years</td>
                          <td>₱ {numberFormatter.format(pagibigYears20)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>15 Years</td>
                          <td>₱ {numberFormatter.format(pagibigYears15)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>10 Years</td>
                          <td>₱ {numberFormatter.format(pagibigYears10)}</td>
                        </tr>
                        <tr className={`border-bottom-green`}>
                          <td>5 Years</td>
                          <td>₱ {numberFormatter.format(pagibigYears5)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </LI>
              </UL>
            </div>
            <div className="notice">
              <div>IMPORTANT NOTICE:</div>
              All amounts quoted are in Philippine Peso.
              <br />
              All computations are for illustration purposes only. These do not
              constitute an approval nor an offer.
              <br />
              Actual computations based on prevailing rates will be provided by
              your Bank/Pagibig Account officer upon loan application.
            </div>
          </div>
          <Button
            color="btn btn-outline-primary m-t-10"
            size="lg"
            id="download"
            onClick={downloadQuotation}
          >
            Download
          </Button>
        </div>
      </Col>
    </Fragment>
  );
};
export default ProductPlaceOrder;
