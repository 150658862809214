import ForPosting from "../Components/Application/ForPosting";
import CustomerRelationManagement from "../Components/CRM";
import EventCalendar from "../Components/Calendar/Index";
import DeveloperProjects from "../Components/DeveloperProjects";
import Listing from "../Components/Listing";
import ViewListing from "../Components/Listing/ViewListing";
import MorgageCalculator from "../Components/Pages/PageLayout/SimplePage";

export const routes = [
  // //page
  {
    path: `${process.env.PUBLIC_URL}/calculator/`,
    Component: <MorgageCalculator />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/`,
    Component: <ForPosting />,
  },
  {
    path: `${process.env.PUBLIC_URL}/developer-projects/`,
    Component: <DeveloperProjects />,
  },
  {
    path: `${process.env.PUBLIC_URL}/developer-projects/`,
    Component: <DeveloperProjects />,
  },
  {
    path: `${process.env.PUBLIC_URL}/calendar/`,
    Component: <EventCalendar />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/`,
    Component: <CustomerRelationManagement />,
  },
  {
    path: `${process.env.PUBLIC_URL}/listing/`,
    Component: <Listing />,
  },
  {
    path: `${process.env.PUBLIC_URL}/listing/view/:id`,
    Component: <ViewListing />,
  },
];
